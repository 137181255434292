// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-gine-blog-src-components-post-post-page-js": () => import("/opt/build/repo/node_modules/gatsby-theme-gine-blog/src/components/post/post-page.js" /* webpackChunkName: "component---node-modules-gatsby-theme-gine-blog-src-components-post-post-page-js" */),
  "component---node-modules-gatsby-theme-gine-blog-src-components-post-blog-post-js": () => import("/opt/build/repo/node_modules/gatsby-theme-gine-blog/src/components/post/blog-post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-gine-blog-src-components-post-blog-post-js" */),
  "component---node-modules-gatsby-theme-gine-blog-src-components-post-tag-tag-page-js": () => import("/opt/build/repo/node_modules/gatsby-theme-gine-blog/src/components/postTag/tag-page.js" /* webpackChunkName: "component---node-modules-gatsby-theme-gine-blog-src-components-post-tag-tag-page-js" */),
  "component---node-modules-gatsby-theme-gine-blog-src-pages-404-js": () => import("/opt/build/repo/node_modules/gatsby-theme-gine-blog/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-gine-blog-src-pages-404-js" */),
  "component---node-modules-gatsby-theme-gine-blog-src-pages-archives-js": () => import("/opt/build/repo/node_modules/gatsby-theme-gine-blog/src/pages/archives.js" /* webpackChunkName: "component---node-modules-gatsby-theme-gine-blog-src-pages-archives-js" */),
  "component---node-modules-gatsby-theme-gine-blog-src-pages-books-js": () => import("/opt/build/repo/node_modules/gatsby-theme-gine-blog/src/pages/books.js" /* webpackChunkName: "component---node-modules-gatsby-theme-gine-blog-src-pages-books-js" */),
  "component---node-modules-gatsby-theme-gine-blog-src-pages-links-js": () => import("/opt/build/repo/node_modules/gatsby-theme-gine-blog/src/pages/links.js" /* webpackChunkName: "component---node-modules-gatsby-theme-gine-blog-src-pages-links-js" */),
  "component---node-modules-gatsby-theme-gine-blog-src-pages-moments-js": () => import("/opt/build/repo/node_modules/gatsby-theme-gine-blog/src/pages/moments.js" /* webpackChunkName: "component---node-modules-gatsby-theme-gine-blog-src-pages-moments-js" */),
  "component---node-modules-gatsby-theme-gine-blog-src-pages-tags-js": () => import("/opt/build/repo/node_modules/gatsby-theme-gine-blog/src/pages/tags.js" /* webpackChunkName: "component---node-modules-gatsby-theme-gine-blog-src-pages-tags-js" */)
}

